

const HomePage = () => {
  return (
    <div className="container d-flex vh-100  align-items-center justify-content-center">
      <div className="row  ">
        <div className="col-md-6">
          <button className="btn btn-primary ">Register</button>
        </div>
      </div>
    </div>
  )
}

export default HomePage