/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.employee);

  return isAuthenticated ? children : <Navigate to="/admin/login" replace />
}

export default AdminProtectedRoute