import { Link } from "react-router-dom"
import CreateVacancy from "./CreateVacancy.jsx"
import { useEffect, useState } from "react";
import axios from "axios";
import { server } from "../../../server/server";


const ApplicationsPage = () => {
  const [users, setUsers] = useState([]);
  // Fetch users when component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${server}/jobs/applications`);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);
  return (
    <div className="content-wrapper">
      {/* Content Header */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Applications</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/** main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col">
              <h3>Applications</h3>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-sm btn-primary"
                data-toggle="modal"
                data-target="#createVacancy"
              >
                Create Vacancy
              </button>

              {/*Create User Modal */}
              <CreateVacancy />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((user, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.email}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ApplicationsPage