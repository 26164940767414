import { useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"


const AdminSidebar = () => {

  const { employee } = useSelector((state) => state.employee);

  return (

    <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ position: 'fixed', height: '100vh' }}>
      {/* Brand Logo */}
      <NavLink exact="true" to="/admin/dashboard" className="brand-link">
        <span className="brand-text font-weight-light">Starkward | Admin</span>
      </NavLink>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-2 pb-3 mb-1 d-flex">
          <div className="image">
            <img src="" className="img-circle elevation-2" alt="" />
          </div>
          <div className="info">
            <Link to="/admin/profile" className="d-block">
              {employee?.firstName}{" "}{employee?.lastName}
            </Link>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-1">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-header">HOME</li>
            <li className="nav-item">
              <NavLink exact="true" to="/admin/dashboard" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Dashboard
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin/departments" className="nav-link">
                <i className="nav-icon fas fa-user-lock"></i>
                <p>Departments</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin/roles" className="nav-link">
                <i className="nav-icon fas fa-user-lock"></i>
                <p>Roles</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact="true" to="/admin/staff" className="nav-link">
                <i className="nav-icon fas fa-users-cog"></i>
                <p>
                  Employees
                </p>
              </NavLink>
            </li>
            <li className="nav-header">BUSINESS MANAGEMENT</li>
            <li className="nav-item">
              <NavLink exact="true" to="/admin/users" className="nav-link">
                <i className="nav-icon fas fa-users"></i>
                <p>
                  Users
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin/customers" className="nav-link">
                <i className="fas fa-users nav-icon"></i>
                <p>Customers</p>
              </NavLink>
            </li>
            <li className="nav-header">PRICING</li>
            <li className="nav-item">
              <NavLink to="/admin/packages" className="nav-link">
                <i className="fas fa-cubes nav-icon"></i>
                <p>Packages</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin/transactions" className="nav-link">
                <i className="fas fa-receipt nav-icon"></i>
                <p>Transactions</p>
              </NavLink>
            </li>

            <li className="nav-header">SETTINGS & SUPPORT</li>
            <li className="nav-item">
              <NavLink to="/admin/settings" className="nav-link">
                <i className="fas fa-cogs nav-icon"></i>
                <p>General Settings</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin/support" className="nav-link">
                <i className="fas fa-phone-square nav-icon"></i>
                <p>Support</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/admin/profile" className="nav-link">
                <i className="fas fa-user-alt nav-icon"></i>
                <p>User Profile</p>
              </NavLink>
            </li>

          </ul>
        </nav>
      </div>
    </aside>
  )
}

export default AdminSidebar