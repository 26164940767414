/* eslint-disable react/prop-types */
import axios from 'axios';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { server } from '../../../server/server';

const CreatePackage = ({ show, handleClose }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [features, setFeatures] = useState([]);

  // const navigate = useNavigate();

  const handleSubmit = () => {
    // Package data to be sent to the server
    const packageData = {
      name,
      description,
      price: parseFloat(price), // Convert price to a number
      features,
    };

    // Send packageData to the server using Axios
    axios.post(`${server}/packages/create-package`, packageData)
      .then(res => {
        toast.success(res.data.message);
        // Reset form fields
        setName('');
        setDescription('');
        setPrice('');
        setFeatures([]);
        handleClose();
        // Navigate to /admin/packages upon successful submission
        window.location.reload();
        // navigate('/admin/packages');
      })
      .catch(error => {
        console.error('Error creating package:', error);
        console.log(error.response)
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Package</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="packageName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter package name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="packageDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter package description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="packagePrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter package price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="packageFeatures">
            <Form.Label>Features</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter package features (one per line)"
              value={features.join('\n')}
              onChange={(e) => setFeatures(e.target.value.split('\n'))}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePackage;
