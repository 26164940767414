
import AdminLogin from '../../../components/auth/login/AdminLogin';

const AdminLoginPage = () => {
  return (
    <>
      <AdminLogin />
    </>
  )
}

export default AdminLoginPage