/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { server } from "../../../server/server";
import axios from "axios";

const RoleModal = ({ show, handleClose, role }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [permissions, setPermissions] = useState("");
  const [level, setLevel] = useState("");
  const [active, setActive] = useState(true); // Set active to true by default
  const [scope, setScope] = useState("");
  const [restrictions, setRestrictions] = useState("");

  useEffect(() => {
    if (role) {
      setName(role.name || "");
      setDescription(role.description || "");
      setPermissions(role.permissions ? role.permissions.join(", ") : "");
      setLevel(role.level || "");
      setActive(role.active ?? true); // Set active to true if not provided
      setScope(role.scope || "");
      setRestrictions(role.restrictions ? role.restrictions.join(", ") : "");
    }
  }, [role]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const roleData = {
      name,
      description,
      permissions: permissions
        .split(",")
        .map((permission) => permission.trim()),
      level,
      active, // Use active state value
      scope,
      restrictions: restrictions
        .split(",")
        .map((restriction) => restriction.trim()),
    };

    try {
      const url = role && role._id ? `${server}/roles/edit/${role._id}` : `${server}/roles/create-role`;
      const requestMethod = role && role._id ? "put" : "post";
      const response = await axios[requestMethod](url, roleData);
      toast.success(response.data.message);
      handleClose();
      window.location.reload(); // Reloading the page might not be ideal; consider updating state instead
    } catch (error) {
      console.error("Error creating/editing role:", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {role && role._id ? "Edit Role" : "Create Role"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="permissions">
            <Form.Label>Permissions</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter permissions (comma-separated)"
              value={permissions}
              onChange={(e) => setPermissions(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="level">
            <Form.Label>Level</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter level"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="active">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Form.Group>
          <Form.Group controlId="scope">
            <Form.Label>Scope</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter scope"
              value={scope}
              onChange={(e) => setScope(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="restrictions">
            <Form.Label>Restrictions</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter restrictions (comma-separated)"
              value={restrictions}
              onChange={(e) => setRestrictions(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            {role && role._id ? "Update" : "Create"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RoleModal;
