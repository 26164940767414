import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../../server/server';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import EmployeeModal from './EmployeeModal'; // Adjusted import

const EmployeesPage = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [employees, setEmployees] = useState([]);
  const [editEmployeeId, setEditEmployeeId] = useState(null); // Adjusted state

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${server}/admin/get-all-employees`);
        setEmployees(response.data);
      } catch (error) {
        console.log(error.data.response.message);
      }
    };

    fetchEmployees();
  }, []);

  const handleEdit = (employeeId) => {
    setEditEmployeeId(employeeId);
    handleShowModal(); // Show the EmployeeModal when editing
  };

  const handleDelete = async (employeeId) => {
    try {
      const response = await axios.delete(`${server}/employees/${employeeId}`);
      if (response.status === 200) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        console.error('Failed to delete employee.');
      }
    } catch (error) {
      console.error('Error deleting employee:', error.response.data.message);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-left">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Employees</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col">
              <h3>Employees</h3>
            </div>
            <div className="col-auto">
              <Button variant="primary btn-sm" onClick={handleShowModal}>
                Create New
              </Button>
              <EmployeeModal
                show={showModal} // Pass showModal state
                handleClose={handleCloseModal} // Pass handleCloseModal function
                employee={employees.find(employee => employee._id === editEmployeeId)} // Find employee data based on editEmployeeId
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Department</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {employees && employees.map((employee, index) => (
                  <tr key={employee._id}>
                    <td>{index + 1}</td>
                    <td>{employee.name}</td>
                    <td>{employee.email}</td>
                    <td>{employee.department.name}</td>
                    <td>{employee.role.name}</td>
                    <td>
                      <Button variant="info" size="sm" onClick={() => handleEdit(employee._id)}>
                        <FaEdit size={15} />
                      </Button>{' '}
                      <Button variant="danger" size="sm" onClick={() => handleDelete(employee._id)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmployeesPage;
