import { useState } from 'react';
import axios from 'axios';
import { server } from '../../../server/server';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateUser = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 'User' // Default role
  });

  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send POST request to create a new user
      const response = await axios.post(`${server}/user/create-user`, formData);
      console.log('New user created:', response.data);
      // Close the modal or handle success in another way
      navigate("/admin/users")
    } catch (error) {
      // console.error('Error creating user:', error);
      // Handle error, e.g., display error message to the user
      toast.error(error.response.data.message)
    }
  };

  return (
    <div className="modal fade" id="addUserModal" data-backdrop="static" data-keyboard="false">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Add User</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" placeholder="Enter name" value={formData.name} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" placeholder="Enter email" value={formData.email} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="role">Role</label>
                <select className="form-control" id="role" value={formData.role} onChange={handleChange}>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
