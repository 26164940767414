import axios from "axios";
import { server } from "../../server/server";

// load employee
export const loadEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadEmployeeRequest",
    });

    const { data } = await axios.get(`${server}/admin/get-admin`, {
      withCredentials: true,
    });

    dispatch({
      type: "LoadEmployeeSuccess",
      payload: data.employee,
    });

  } catch (error) {
    dispatch({
      type: "LoadEmployeeFail",
      payload: error.response.data.message,
    });
  }
};
