import axios from "axios"; // Import axios
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../../server/server";
import TooltipButton from "../../components/TooltipButton/TooltipButton";
import DepartmentModal from "./DepartmentModal"; // Adjusted import

const DepartmentsPage = () => {
  const [showDepartmentModal, setShowDepartmentModal] = useState(false); // Adjusted state name
  const [editDepartmentId, setEditDepartmentId] = useState(null);

  const handleCloseDepartmentModal = () => setShowDepartmentModal(false); // Adjusted function name
  const handleShowDepartmentModal = () => setShowDepartmentModal(true); // Adjusted function name

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${server}/departments`);
        setDepartments(response.data);
      } catch (error) {
        console.log(error.response.data.message);
      }
    };

    fetchDepartments();
  }, []);

  const handleEdit = (departmentId) => {
    setEditDepartmentId(departmentId);
    handleShowDepartmentModal(); // Show the DepartmentModal when editing
  };

  const handleDelete = async (departmentId) => {
    try {
      const response = await axios.delete(
        `${server}/departments/delete/${departmentId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        console.error("Failed to delete department.");
      }
    } catch (error) {
      console.error("Error deleting department:", error.message);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-left">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Departments</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col">
              <h3>Departments</h3>
            </div>
            <div className="col-auto">
              <Button
                variant="primary btn-sm"
                onClick={handleShowDepartmentModal}
              >
                Create New
              </Button>
              <DepartmentModal
                show={showDepartmentModal} // Pass showDepartmentModal state
                handleClose={handleCloseDepartmentModal} // Pass handleCloseDepartmentModal function
                department={departments.find(
                  (department) => department._id === editDepartmentId
                )} // Find department data based on editDepartmentId
              />
            </div>
          </div>
          <div className="row">
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Responsibilities</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {departments &&
                    departments.map((department, index) => (
                      <tr key={department._id}>
                        <td>{index + 1}</td>
                        <td>{department.name}</td>
                        <td className="description-column">
                          {department.description}
                        </td>
                        <td>
                          <TooltipButton
                            onClick={() => handleEdit(department._id)}
                            variant="info"
                            size="sm"
                            icon={<FaEdit size={15} />}
                            tooltipText="Edit"
                            tooltipPlacement="top"
                            marginRight="10px"
                          />{" "}
                          <TooltipButton
                            onClick={() => handleDelete(department._id)}
                            variant="danger"
                            size="sm"
                            icon={<FaTrash />}
                            tooltipText="Delete"
                            tooltipPlacement="top"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DepartmentsPage;
