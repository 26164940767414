import RegisterBusiness from "../../../components/auth/register/RegisterBusiness"


const AdminRegisterPage = () => {
  return (
    <div>
      <RegisterBusiness />
    </div>
  )
}

export default AdminRegisterPage