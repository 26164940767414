/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../../server/server";

const EmployeeModal = ({ show, handleClose, employee }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // Fetch departments
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${server}/departments`);
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    // Fetch roles
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${server}/roles`);
        setRoles(response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchDepartments();
    fetchRoles();
  }, []);

  useEffect(() => {
    if (employee) {
      setName(employee.name || "");
      setEmail(employee.email || "");
      setDepartment(employee.department || "");
      setRole(employee.role._id || ""); // Store the role ID
    }
  }, [employee]);

  const handleSubmit = () => {
    // Validate the form fields
    if (!name.trim() || !email.trim() || !department.trim() || !role.trim()) {
      toast.error('All fields are required.');
      return;
    }

    const employeeData = {
      name,
      email,
      department,
      role,
    };

    const requestMethod = employee && employee._id ? "put" : "post";
    const url =
      employee && employee._id
        ? `${server}/employees/edit/${employee._id}`
        : `${server}/admin/create`;

    axios[requestMethod](url, employeeData)
      .then((res) => {
        toast.success(res.data.message);
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.response.data.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {employee && employee._id ? "Edit Employee" : "Create Employee"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter employee name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter employee email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="department">
            <Form.Label>Department</Form.Label>
            <Form.Control
              as="select"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              required
            >
              <option value="">Select Department</option>
              {departments.map((dep) => (
                <option key={dep._id} value={dep._id}>
                  {dep.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="role">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role._id} value={role._id}>
                  {role.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {employee && employee._id ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeModal;
