import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CreatePackage from './CreatePackage.jsx';
import axios from 'axios';
import { server } from '../../../server/server';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

const PackagesPage = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${server}/packages`);
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  const handleEdit = (packageId) => {
    // Logic for editing package
    console.log(`Editing package with ID: ${packageId}`);
  };

  const handleDelete = async (packageId) => {
    try {
      // Make a DELETE request to delete the package with the specified ID
      const response = await axios.delete(`${server}/packages/${packageId}`);

      // Check if the request was successful
      if (response.status === 200) {
        toast.success(response.data.message);
        window.location.reload();
      } else {
        console.error('Failed to delete package.');
      }
    } catch (error) {
      console.error('Error deleting package:', error.message);
    }
  };

  return (
    <div className="content-wrapper">
      {/* Content Header */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Packages</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col">
              <h3>Packages</h3>
            </div>
            <div className="col-auto">
              <Button variant="primary btn-sm" onClick={handleShowModal}>
                Create New
              </Button>
              <CreatePackage show={showModal} handleClose={handleCloseModal} />
            </div>
          </div>
          {/**table */}
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Features</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {packages && packages.map((pkg, index) => (
                  <tr key={pkg._id}>
                    <td>{index + 1}</td>
                    <td>{pkg.name}</td>
                    <td>{pkg.description}</td>
                    <td>{pkg.price}</td>
                    <td>
                      <ul>
                        {pkg.features.map((feature, idx) => (
                          <li key={idx}>{feature}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <Button variant="info" size="sm" onClick={() => handleEdit(pkg._id)}>
                        <FaEdit size={15} />
                      </Button>{' '}
                      <Button variant="danger" size="sm" onClick={() => handleDelete(pkg._id)}>
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PackagesPage;
