import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { employeeReducer } from "./reducers/employeeReducer";
import { userReducer } from "./reducers/userReducer";

// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  employee: employeeReducer,
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  serialize: (data) => JSON.stringify(data),
  deserialize: (data) => JSON.parse(data),
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create persistor
const persistor = persistStore(store);

export { persistor, store };

