import { useState } from 'react';
import axios from 'axios';
import { server } from '../../../server/server';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateCustomer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
    active: true
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send POST request to create a new customer
      const res = await axios.post(`${server}/customers/create-customer`, formData);
      toast.success(res.data.message);

      // Close the modal on success
      navigate("/admin/customers");
    } catch (error) {
      console.error('Error creating customer:', error);
      // Handle error, e.g., display error message to the user
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="modal fade" id="createCustomer" data-backdrop="static" data-keyboard="false">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Create Customer</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" id="name" placeholder="Enter name" value={formData.name} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" placeholder="Enter email" value={formData.email} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input type="tel" className="form-control" id="phoneNumber" placeholder="Enter phone number" value={formData.phoneNumber} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input type="text" className="form-control" id="address" placeholder="Enter address" value={formData.address} onChange={handleChange} />
              </div>
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" id="active" checked={formData.active} onChange={handleCheckboxChange} />
                <label className="form-check-label" htmlFor="active">Active</label>
              </div>
              <button type="submit" className="btn btn-primary">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomer;
