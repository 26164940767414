import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { server } from '../../../server/server';
import TooltipButton from '../../components/TooltipButton/TooltipButton';
import RoleModal from './RoleModal';

const RolesPage = () => {
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [editRoleId, setEditRoleId] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${server}/roles`);
        if (response.ok) {
          const data = await response.json();
          setRoles(data);
        } else {
          console.error('Error fetching roles:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const handleEdit = (roleId) => {
    setEditRoleId(roleId);
    setShowRoleModal(true);
  };

  const handleDelete = async (roleId) => {
    try {
      const response = await fetch(`${server}/roles/delete/${roleId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        const data = await response.json();
        toast.success(data.message);
        window.location.reload();
      } else {
        console.error('Failed to delete role.');
      }
    } catch (error) {
      console.error('Error deleting role:', error.message);
    }
  };

  const handleCloseRoleModal = () => setShowRoleModal(false);
  const handleShowRoleModal = () => setShowRoleModal(true);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Roles</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col">
              <h3>Roles</h3>
            </div>
            <div className="col-auto">
              <Button variant="primary btn-sm" onClick={handleShowRoleModal}>
                Create New
              </Button>
              <RoleModal
                show={showRoleModal}
                handleClose={handleCloseRoleModal}
                role={roles.find((role) => role._id === editRoleId)}
              />
            </div>
          </div>
          <div className="row">
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Permissions</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {roles.map((role, index) => (
                    <tr key={role._id}>
                      <td>{index + 1}</td>
                      <td>{role.name}</td>
                      <td>{role.description}</td>
                      <td>{role.permissions.join(", ")}</td>
                      <td>
                        <TooltipButton
                          onClick={() => handleEdit(role._id)}
                          variant="info"
                          size="sm"
                          icon={<FaEdit size={15} />}
                          tooltipText="Edit"
                          tooltipPlacement="top"
                          marginRight="10px"
                        />
                        <TooltipButton
                          onClick={() => handleDelete(role._id)}
                          variant="danger"
                          size="sm"
                          icon={<FaTrash />}
                          tooltipText="Delete"
                          tooltipPlacement="top"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RolesPage;
