import { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../../server/server";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateVacancy = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    requirements: "",
    responsibilities: "",
  });

  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await axios.get(`${server}/roles/all-roles`);
        setRoles(response.data);
      } catch (error) {
        toast.error("Error fetching roles");
      }
    }
    fetchRoles();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${server}/vacancy/create`, formData);
      console.log("New vacancy created:", response.data);
      navigate("/admin/vacancies");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div
      className="modal fade"
      id="createVacancy"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Create Vacancy
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <select className="form-control" id="title" value={formData.title} onChange={handleChange}>
                  <option value="">Select Title</option>
                  {roles.map((role, index) => (
                    <option key={index} value={role.name}>{role.name}</option> // Use role.name instead of role
                  ))}
                </select>

              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-control"
                  id="description"
                  placeholder="Enter description"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="requirements">Requirements</label>
                <textarea
                  className="form-control"
                  id="requirements"
                  placeholder="Enter requirements"
                  value={formData.requirements}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="responsibilities">Responsibilities</label>
                <textarea
                  className="form-control"
                  id="responsibilities"
                  placeholder="Enter responsibilities"
                  value={formData.responsibilities}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateVacancy;
