import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { server } from "../../../../server/server";

const RegisterBusiness = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create an object containing the form data

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    const formData = {
      firstName, lastName, email, companyName,
      password, confirmPassword
    };

    // Send a POST request with Axios to your server
    await axios
      .post(`${server}/admin/register-business`, formData)
      .then((res) => {
        // Check if the response indicates success
        if (res.data.success === true) {
          // Display the success message from the backend
          toast.success(res.data.message);
          // Clear form fields
          setFirstName("");
          setLastName("");
          setEmail("");
          setCompanyName("");
          setPhoneNumber("");
          setPassword("");
          setConfirmPassword("");

          // Navigate to /admin/otp-confirmation and pass the email as a state
          navigate("/admin/otp-confirmation", { state: { email } });

        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="container mt-5 py-5 px-3 card">
      <h2 className="text-center">Request a Demo</h2>
      <p className="text-center">Simply fill out the form and we’ll be in touch.</p>
      <form className="mt-3" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group col-6 col-md-6">
            <label htmlFor="firstName">First Name :</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-6 col-md-6">
            <label htmlFor="lastName">Last Name :</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="businessName">Business Name :</label>
            <input
              type="text"
              className="form-control"
              id="businessName"
              placeholder="Please enter your business name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="email">Email :</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Please enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number:
          </label>
          <input
            type="tel"
            className="form-control"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password:
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password:
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default RegisterBusiness;
