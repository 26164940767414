/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../../../server/server";

const OtpConfirmation = () => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Additional validation logic can be added here before making the API call

    await axios
      .post(`${server}/admin/confirm-otp`, { email, otp })
      .then((res) => {
        toast.success(res.data.message);
        // Optionally, you can redirect the user to a different page after successful confirmation
        navigate("/admin/login")
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="auth vh-100 align-items-center d-flex justify-content-center bg-gradient-navy text-navy">
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header text-center">OTP Confirmation</div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="otp" className="form-label">
                      Enter OTP:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="otp"
                      name="otp"
                      placeholder="Enter the OTP sent to your email"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Confirm OTP
                  </button>
                  <div className="mb-3 text-center">
                    Didn't receive the OTP?{" "}
                    <Link to="/admin/forgot-password" className="text-blue">
                      Resend OTP
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpConfirmation;
