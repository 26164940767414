/* eslint-disable react/prop-types */
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const TooltipButton = ({
  onClick,
  variant,
  size,
  icon,
  tooltipText,
  tooltipPlacement,
  marginRight,
}) => {
  const buttonStyle = {
    marginRight: marginRight || 0,
  };

  return (
    <OverlayTrigger
      overlay={<Tooltip>{tooltipText}</Tooltip>}
      placement={tooltipPlacement}
    >
      <Button variant={variant} size={size} onClick={onClick} style={buttonStyle}>
        {icon}
      </Button>
    </OverlayTrigger>
  );
};

export default TooltipButton;
