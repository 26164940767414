import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { server } from "../../../server/server";
import CreateCustomer from "./CreateCustomer.jsx";
import { toast } from "react-toastify";
import EditCustomer from "./EditCustomer.jsx"; // Import EditCustomer component here

const CustomersPage = () => {
  const [customers, setCustomers] = useState([]);
  const [editingCustomer, setEditingCustomer] = useState(null); // State to hold the customer being edited

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${server}/customers`);
        setCustomers(response.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchCustomers();
  }, []);

  const deleteCustomer = async (customerId) => {
    try {
      await axios.delete(`${server}/customers/customer/${customerId}`);
      toast.success("Customer deleted successfully");
      setCustomers(customers.filter(customer => customer._id !== customerId)); // Update customers state after deletion
    } catch (error) {
      console.error("Error deleting customer:", error);
      toast.error("Failed to delete customer");
    }
  };

  const editCustomer = (customerId) => {
    // Set the customer ID to state to trigger rendering of EditCustomer component
    setEditingCustomer(customerId);
  };

  const cancelEdit = () => {
    // Reset editingCustomer state to null to hide the EditCustomer component
    setEditingCustomer(null);
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Roles</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center mb-3">
            <div className="col">
              <h3>Customers</h3>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-sm btn-primary"
                data-toggle="modal"
                data-target="#createCustomer"
              >
                Add Customer
              </button>
              <CreateCustomer />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, index) => (
                  <tr key={customer._id}>
                    <th scope="row">{index + 1}</th>
                    <td>{customer.name}</td>
                    <td>{customer.email}</td>
                    <td>{customer.phoneNumber}</td>
                    <td>{customer.address}</td>
                    <td>{customer.active ? "Yes" : "No"}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm "
                        data-toggle="modal"
                        data-target="#editCustomer"
                        onClick={() => editCustomer(customer._id)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger btn-sm ml-1"
                        onClick={() => deleteCustomer(customer._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {editingCustomer && (
        <EditCustomer
          customerId={editingCustomer}
          cancelEdit={cancelEdit} // Pass cancelEdit function as prop
        />
      )}
    </div>
  );
};

export default CustomersPage;
