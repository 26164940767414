/* eslint-disable react/prop-types */
import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { server } from "../../../server/server";

const DepartmentModal = ({ show, handleClose, department }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    // If department data is provided, populate the state with it
    if (department) {
      setName(department.name || "");
      setDescription(department.description || "");
    }
  }, [department]);

  const handleSubmit = () => {
    // Validate the form fields
    if (!name.trim() || !description.trim()) {
      toast.error('Both name and description are required.');
      return;
    }

    const departmentData = {
      name,
      description,
    };

    // Determine the request URL and method based on whether it's an edit or creation
    const requestMethod = department && department._id ? "put" : "post";
    const url = department && department._id
      ? `${server}/departments/edit/${department._id}`
      : `${server}/departments/create`;

    axios[requestMethod](url, departmentData).then((res) => {
      toast.success(res.data.message);
      handleClose();
      // Reload the page upon successful submission
      window.location.reload();
    })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error.response.data.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {department && department._id ? "Edit Department" : "Create Department"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="departmentName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter department name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="departmentDesc">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter department description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {department && department._id ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DepartmentModal;
