import { useEffect } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AdminChangePassword,
  AdminDashboard,
  AdminFooter,
  AdminLoginPage,
  AdminNavbar,
  AdminRegisterPage,
  AdminSidebar,
  ApplicationsPage,
  CustomersPage,
  DepartmentsPage,
  EmployeesPage,
  OtpConfirmation,
  PackagesPage,
  RolesPage,
  SettingsPage,
  SupportPage,
  TransactionsPage,
  UserProfilePage,
  UsersPage
} from "./admin/routes/AdminRoutes";

import AdminProtectedRoute from "./admin/routes/AdminProtectedRoutes";
import { loadEmployee } from "./redux/actions/employeeAction";
import { store } from "./redux/store";
import { Footer, HomePage, Navbar } from "./routes/ClientRoutes";

const App = () => {
  useEffect(() => {
    // store.dispatch(loadUser());
    store.dispatch(loadEmployee());
  }, []);

  const Layout = () => {
    return (
      <>
        <Navbar />
        <div className="app">
          <Outlet />
        </div>
        <Footer />
      </>
    );
  };

  const AdminLayout = () => {
    return (
      <div className="wrapper">
        <AdminNavbar />
        <AdminSidebar />
        <Outlet />
        <AdminFooter />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <AdminProtectedRoute>
          <AdminLayout />
        </AdminProtectedRoute>
      ),
      children: [
        {
          path: "/admin/dashboard",
          element: <AdminDashboard />
        },
        {
          path: "/admin/departments",
          element: <DepartmentsPage />
        },
        {
          path: "/admin/staff",
          element: <EmployeesPage />
        },
        {
          path: "/admin/users",
          element: <UsersPage />
        },
        {
          path: "/admin/applications",
          element: <ApplicationsPage />
        },
        {
          path: "/admin/roles",
          element: <RolesPage />
        },
        {
          path: "/admin/customers",
          element: <CustomersPage />
        },
        {
          path: "/admin/packages",
          element: <PackagesPage />
        },
        {
          path: "/admin/transactions",
          element: <TransactionsPage />
        },
        {
          path: "/admin/settings",
          element: <SettingsPage />
        },
        {
          path: "/admin/support",
          element: <SupportPage />
        },
        {
          path: "/admin/profile",
          element: <UserProfilePage />
        },
      ]
    },
    {
      path: "/admin/register",
      element: <AdminRegisterPage />,
    },
    {
      path: "/admin/login",
      element: <AdminLoginPage />,
    },
    {
      path: "/admin/otp-confirmation",
      element: <OtpConfirmation />,
    },
    {
      path: "/admin/verify-account",
      element: <AdminChangePassword />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
};

export default App;
