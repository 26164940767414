/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../../../server/server";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${server}/admin/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      );

      toast.success(response.data.message);
      navigate("/admin/dashboard");
      // window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // If the error status is 401, navigate to "/admin/otp-confirmation"
        navigate('/admin/verify-account');
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }

    }
  };
  return (
    <div
      className="vh-100 align-items-center d-flex justify-content-center"
      style={{ backgroundColor: "#002c2b" }}
    >
      <div className="container mt-2">
        <div className="row justify-content-center mx-0 ">
          <div className="col-sm-6 col-md-7 col-lg-6">
            <div className="card">
              <div className="card-header text-center">Admin | Login</div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      autoComplete="email"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password :
                    </label>
                    <div className="input-group">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        autoComplete="current-password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span className="input-group-text">
                        <i
                          className={
                            passwordVisible ? "bx bxs-show" : "bx bxs-hide"
                          }
                          onClick={togglePasswordVisibility}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div className="mb-3 d-flex justify-content-between">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="rememberMe"
                      />
                      <label className="form-check-label" htmlFor="rememberMe">
                        Remember Me
                      </label>
                    </div>
                    <Link to="/admin/forgot-password" className="text-blue">
                      Forgot Password
                    </Link>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                  <div className="mb-3 text-center">
                    Don't have an account?{" "}
                    <Link to="/admin/register" className="text-blue">
                      Create Account
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
