import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../../../server/server";

const AdminChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    try {
      const response = await axios.post(
        `${server}/admin/change-password`,
        {
          oldPassword,
          newPassword,
        },
        { withCredentials: true }
      );

      toast.success(response.data.message);
      navigate("/admin/dashboard");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div
      className="vh-100 align-items-center d-flex justify-content-center"
      style={{ backgroundColor: "#002c2b" }}
    >
      <div className="container mt-2">
        <div className="row justify-content-center mx-0 ">
          <div className="col-sm-6 col-md-7">
            <div className="card">
              <div className="card-header text-center">Admin | Change Password</div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="oldPassword" className="form-label">
                      Old Password:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="Enter your old password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">
                      New Password:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="newPassword"
                      name="newPassword"
                      placeholder="Enter your new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm your new password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setPasswordsMatch(e.target.value === newPassword);
                      }}
                      required
                    />
                    {!passwordsMatch && (
                      <div className="text-danger">Passwords do not match</div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary align-self-right">
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminChangePassword;
