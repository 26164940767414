const AdminFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="main-footer bg-dark">
      <strong>Copyright &copy; 2016- {currentYear}.
        <a href="https://www.starkward.com" className="text-blue mx-1 ">Starkward Corporation</a>.
      </strong>
      {" "} All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 2.0
      </div>
    </footer>

  );
};

export default AdminFooter;
